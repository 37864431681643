<template>
    <div>
        <b-button variant="primary" v-if="manage" v-b-modal.add-app-notifications>
            <span class="text-nowrap">Add Notification</span>
        </b-button>
        <b-modal id="add-app-notifications" hide-footer size="md" centered title="Add Notification" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{
            errorMsg }}</b-alert>
                <p class="mb-0 ">Notification Name</p>
                <b-form-input class="mb-1" v-model="NotificationName" placeholder="Notifications Name" type="text" />
                <div class="modal-footer">
                    <b-button @click="addTheme" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small class="mr-1" />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
    },
    data() {
        return {
            NotificationName: "",
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
        NotificationName(newValue) {
            this.updatePaths();
        },
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        updatePaths() {
            if (this.NotificationName) {
                this.vuePath = `${this.baseVuePath}${this.NotificationName}.vue`;
                this.reactNativePath = `${this.baseReactNativePath}${this.NotificationName}.js`;
            } else {
                this.vuePath = this.baseVuePath;
                this.reactNativePath = this.baseReactNativePath;
            }
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.NotificationName = "";
            this.errorMsg = "";
            this.updatePaths();
        },
        async addTheme() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                let themeData = {
                    name: this.NotificationName,
                    vuePath: this.vuePath,
                    reactNativePath: this.reactNativePath,
                }
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/theme",
                    themeData,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    });

                if (resp.data.status === 200) {
                    this.alertMessageToast = resp.data.message;
                    this.showToast("success");
                    this.$emit('fetchThemes');
                    this.$bvModal.hide("add-themes-modal");
                    this.loading = false;
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg = error.response.data.error || 'An error occurred';
                } else if (error.request) {
                    this.errorMsg = 'No response received from server. Please check your network connection.';
                } else {
                    this.errorMsg = 'Error in setting up the request: ' + error.message;
                }
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.NotificationName.length) {
                this.errorMsg = "Theme name required."
                return;
            }

            if (!this.vuePath.length) {
                this.errorMsg = "Vue Path required."
                return;
            }

            if (!this.reactNativePath.length) {
                this.errorMsg = "React native Path required."
                return;
            }

            return "ok";
        },
    },
    props: ["manage", "token"],
};
</script>
